<template>
  <div>
    <default-layout>
      <section class="inner-page">
        <div class="container" style="text-align: justify;">
          <img
            src="@/assets/imagenes/LOGO  ACD CONSULTORES.png"
            alt="logo"
            style="width: 200px; height: auto;"
          />
          <p></p>

          <h1>TÉRMINOS Y CONDICIONES</h1>

          <br />
          Este contrato describe los términos y condiciones generales (“TÉRMINOS
          Y CONDICIONES”) aplicables al uso de los servicios ofrecidos y/o
          prestados por ACD CONSULTORES, S.A. DE C.V. (“ACD NÓMINA”) dentro del
          sitio www.acdnomina.com (“EL SITIO”).
          <br />
          <br />

          Cualquier persona física o moral que desee acceder y/o usar el sitio o
          los servicios, podrá hacerlo sujetándose a los TÉRMINOS Y CONDICIONES,
          junto con las demás políticas y principios por los que se rige ACD
          NÓMINA.
          <br />
          <br />
          CUALQUIER PERSONA QUE NO ACEPTE LOS PRESENTES TÉRMINOS Y CONDICIONES,
          MISMOS QUE TIENEN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE
          DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.
          <br />
          <br />
          Es responsabilidad del usuario leer, entender y aceptar todas las
          condiciones establecidas en los presentes TÉRMINOS Y CONDICIONES, el
          AVISO DE PRIVACIDAD, así como en los demás documentos incorporados a
          los mismos.
          <br />
          <br />
          Para los efectos de los presentes TÉRMINOS Y CONDICIONES se entiende
          por:
          <br />
          <br />
          a) Aviso de Privacidad. Documento físico y/o electrónico que establece
          las normas bajo las que se trata su información por ACD NÓMINA.
          <br />
          <br />
          b) Contratación. Es la formalización de la prestación de los servicios
          ofrecidos por ACD NÓMINA donde a través del portal comercial, la cual
          se lleva a cabo con la aceptación de manera digital por internet y por
          el uso de los servicios ofrecidos a través de los medios digitales
          contratados de acuerdo a los medios y plazos establecidos para ello.
          <br />
          <br />
          c) ACD NÓMINA es un software para el procesamiento de nómina,
          administración y gestión de personal en materia legal, fiscal y
          contable que se ofrece en el esquema de software como servicio por
          internet y su Titular es ACD CONSULTORES, S.A. DE C.V. con domicilio
          en: Avenida Francisco I. Madero 241 L-11 M-21 “Locales Comerciales”,
          Interior “C”, Col. Venustiano Carranza, C.P. 77012, Chetumal,
          Municipio de Othón P. Blanco, Quintana Roo.
          <br />
          <br />
          d) Portal Comercial. Sitio donde se navega y se lleva a cabo el
          registro a ACD NÓMINA, así como para adquirir el servicio y poder
          realizar el pago en “EL SITIO”.
          <br />
          <br />
          e) Registro. Proceso dentro de “EL SITIO” mediante el cual se genera
          una cuenta de ACD NÓMINA y da acceso a diversos servicios dentro del
          sitio de acuerdo al tipo de registro. Existen cinco tipos de registro:
          i) solicitar contacto directo de un asesor de servicio; ii) solicitar
          una cotización de los servicios; iii) inscripción a cursos e
          información de interés; iv) adquirir los servicios de ACD NÓMINA
          mediante contrato; v) suscripción a la aplicación y tener acceso a
          todos los beneficios que ofrece el portal de acuerdo a lo establecido
          en el sitio.
          <br />
          <br />
          f) Servicio. El uso del software, interfaz, bases de datos y demás
          elementos de ACD NÓMINA y que permiten realizar las operaciones de
          procesamiento de nómina, administración y gestión de personal en
          materia legal, fiscal y contable.
          <br />
          <br />
          g) Titular. Persona física o moral que tiene facultades para
          administrar una cuenta.
          <br />
          <br />
          h) Usuario Principal: Usuario con el cual se regirá el ingreso al
          sistema. Éste tendrá acceso a todas las funciones y módulos de la
          aplicación.
          <br />
          <br />
          i) Usuario Adicional: Cuentas adicionales creadas para el acceso
          independiente al sistema. Estos pueden tener permisos determinados por
          el usuario principal y podrán ser creadas o revocadas únicamente por
          medio de éste.
          <br />
          <br />
          Al registrarte como usuario, contratar o usar el Servicio de ACD
          NÓMINA aceptas tácitamente los siguientes TÉRMINOS Y CONDICIONES:
          <br />
          <br />
          1. Capacidad. Los servicios sólo están disponibles para personas que
          tengan capacidad legal para contratar. Por lo cual, no podrán utilizar
          o ser sujetos a la prestación de los servicios que ofrece ACD NÓMINA:
          i) las personas que no tengan capacidad para contratar por sí o para
          actuar en representación de una persona moral, y ii) menores de edad.
          <br />
          <br />
          2. Suscripción. Es obligatorio, para el usuario y/o titular, completar
          el registro de usuario dentro de “EL PORTAL” en todos sus campos con
          datos válidos, exactos, precisos y verdaderos para poder utilizar los
          servicios que brinda ACD NÓMINA. El usuario garantiza, en todo
          momento, la veracidad, exactitud, vigencia y autenticidad de los datos
          ingresados en el portal comercial y/o en el sistema. ACD NÓMINA no se
          responsabiliza bajo ninguna circunstancia, por la certeza de los datos
          ingresados por los usuarios y/o titulares.
          <br />
          <br />
          3. Creación de la Cuenta. La creación y uso de una cuenta quedará
          sujeta a la aprobación que previamente realice ACD NÓMINA. En caso de
          que se considere que por cualquier razón no se autoriza la creación de
          una cuenta, ACD NÓMINA no queda obligada, de forma alguna, a prestar
          los servicios o crear una cuenta de usuario.
          <br />
          <br />
          El titular será el único facultado para administrar la misma.
          Igualmente será la única persona autorizada para administrar la
          relación con ACD NÓMINA por medio de la interface de conformidad con
          los presentes Términos de Uso.
          <br />
          <br />
          Por lo tanto, será el único responsable del uso que se dé a las claves
          de usuario y contraseñas correspondientes al usuario principal y
          usuarios secundarios correspondientes a la cuenta, así como de las
          operaciones que se realicen con ella. En consecuencia, se manifiesta
          que expresamente entiende y se compromete a no divulgar dicha
          información o, en su caso, hacerse responsable por el mal uso que se
          pudiera dar a los mismos así como por los daños que se pudieran
          ocasionar a su información, a los servidores de ACD NÓMINA, al
          software de ACD NÓMINA, a los servicios prestados por ACD NÓMINA o a
          la información de otros usuarios o clientes de ACD NÓMINA. El usuario
          y/o titular se compromete a notificar a ACD NÓMINA en forma inmediata
          y por medio idóneo y fehaciente, cualquier uso no autorizado de su
          cuenta, así como el ingreso por terceros no autorizados a la misma.
          <br />
          <br />
          4. Timbrado Digital. De conformidad con lo establecido en la Ley de
          Impuesto sobre la Renta y el Código Fiscal de la Federación, ACD
          NÓMINA previo requerimiento, generará los recibos de nómina
          debidamente timbrados mediante un PAC autorizado de acuerdo con los
          requisitos señalados por dicha legislación fiscal para tal efecto.
          <br />
          <br />
          El titular, con su Registro Federal de Contribuyente, en su calidad de
          cliente del servicio de timbrado de nómina digital, prestado a través
          de ACD NÓMINA, consistente en el servicio de generación y
          administración de Comprobantes Fiscales Digitales a través de Internet
          (CFDI'S), por medio de la aceptación de los presentes términos y
          condiciones manifiesta que ACD NÓMINA ha hecho de su conocimiento que:
          <br />
          <br />
          (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A
          efecto de poder garantizar la emisión oportuna de los citados
          comprobantes CFDI'S de acuerdo a su estándar de calidad en la atención
          al
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cliente,
          ACD NÓMINA tendrá la facultad de contratar el servicio de timbrado
          digital con uno o más Proveedores Autorizados de Certificación
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(PAC'S),
          autorizados por el Servicio de Administración Tributaria (SAT).
          <br />
          (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En
          términos de lo establecido en la regla I.2.7.2.1, de la Resolución
          Miscelánea Fiscal para 2013, publicada el día 28 de Diciembre del
          2012, en el
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Diario
          Oficial de la Federación, el titular por su propio derecho o el de su
          representada, según corresponda, manifiesta que conoce y autoriza a
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ACD
          NÓMINA para que a través de los PAC'S a que se refiere el punto
          anterior, se entregue al S.A.T. copia de los comprobantes que le
          hubieran
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sidocertificados
          de tiempo en tiempo, conforme a la obligación que en ese sentido
          impone la citada regla.
          <br />
          (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Conoce
          que la manifestación y autorización a que se refiere el punto
          anterior, quedan registradas en los servidores que administran la
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;información de
          generación y registro de los recibos, mediante el almacenamiento del
          archivo que contienen los presentes términos y
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;condiciones
          (manifestación), debidamente firmada de manera electrónica.
          <br />
          (iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Por lo anterior en
          este acto libera a ACD NÓMINA, así como a cualesquier PAC'S,
          representantes y empleados de ambos que hubieran intervenido
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;en
          la certificación y timbrado de sus CFDI'S, de cualquier
          responsabilidad por brindar al S.A.T. dicha copia, ya que deviene de
          una obligación legal
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ante
          la autoridad fiscal.
          <br />
          Para debida generación del servicio de timbrado de nómina, el usuario
          tendrá que subir su Certificado de Sello Digital (CSD) para su
          generación y envío al S.A.T. El resguardo de éste se realizará en
          nuestro servidor para el uso posterior del servicio.
          <br />
          <br />
          Para la debida generación del servicio de timbrado de nómina a través
          de ACD NÓMINA y en base a la Primera Resolución de Modificaciones a la
          Resolución Miscelánea Fiscal para 2015 y sus Anexos 1, 1-A, 3, 7, 11,
          14, 21 y 23 publicada en el Diario Oficial de la Federación el 03 de
          marzo de 2015, en sustitución de la FIEL, el usuario deberá generar su
          Certificado de Sello Digital (CSD) de conformidad con los
          requerimientos que para tal efecto establezca el S.A.T.
          <br />
          <br />
          La vigencia para el uso de timbres digitales adquiridos por cada
          usuario de acuerdo al paquete contratado, se sujetará a la vigencia
          del paquete de servicio adquirido para tal efecto. Los timbres no son
          acumulables, transcurrido el plazo de la vigencia del servicio, el
          usuario no podrá utilizar los timbres restantes.
          <br />
          <br />
          5. Régimen Aplicable para Emisión de CFDI. El Servicio ofrecido por
          ACD NÓMINA emitirá el CFDI de conformidad con la información
          proporcionada por el usuario, por lo que es única y exclusivamente
          responsabilidad del usuario determinar correctamente el tipo de
          empleado para la asignación del régimen fiscal correspondiente. Los
          timbres emitidos por ACD NÓMINA se reportarán ante el Servicio de
          Administración Tributaria, para el régimen de ‘Sueldos y Salarios’,
          con el tipo 2 y para ‘Asimilados a Salarios’, con el tipo 9. En el
          supuesto de que algún usuario requiera reportar bajo un régimen
          diverso de conformidad con lo establecido en el catalogo publicado por
          el SAT, deberá comunicarse a Soporte Técnico ACD NÓMINA, quien
          verificará su viabilidad y, en caso de confirmarse, procederá con
          dicho cambio. En virtud de lo anterior, ACD NÓMINA bajo ningún
          supuesto es responsable por cualquier cambio, error y/o cancelación en
          la emisión de CFDI’s por información errónea proporcionada por el
          usuario.
          <br />
          <br />
          6. Condiciones de Contratación. La contratación del servicio que
          brinda ACD NÓMINA puede ser efectuado desde el Portal Comercial y/o
          desde sus aplicaciones. ACD NÓMINA usa diversos esquemas tanto de
          prepago como de postpago, según lo establecido en el portal. El uso
          y/o prestación del servicio está condicionado al pago correspondiente
          al mes de servicio. El costo del servicio se expresa en pesos
          mexicanos, moneda de curso legal en los Estados Unidos Mexicanos. Al
          realizarse cualquier pago se establece como el día de corte el mismo
          día de pago del siguiente mes o periodo de tiempo contratado a la
          misma fecha de pago inicial, con lo cual ACD NÓMINA no tiene una fecha
          de corte fija; la fecha de corte se moverá dependiendo del día y el
          periodo contratado en ACD NÓMINA. El esquema de contratación que
          ofrece ACD NÓMINA se basa en paquetes de servicio consistentes en
          tabuladores de costo por número de trabajadores. ACD NÓMINA se reserva
          el derecho de suspender el servicio sin responsabilidad alguna, hasta
          en tanto el usuario no realice dicho ajuste y el pago respectivo.
          <br />
          <br />
          7. Condiciones de Pago. Los pagos se podrán realizar mediante “EL
          SITIO” o a través de los bancos autorizados, en estos casos, es
          necesario confirmar vía correo electrónico los datos bancarios y su
          confirmación. La vigencia del servicio será definida por el usuario y
          de conformidad a la acreditación de sus pagos. Esto quiere decir que
          el servicio permanecerá activo y los TÉRMINOS Y CONDICIONES vigentes,
          siempre y cuando la cuenta se encuentre con pago a su favor. Para
          realizar de manera exitosa el proceso de pago, serán requeridos y
          resguardados bajo los términos establecidos en el Aviso de Privacidad
          los siguientes datos de acuerdo al método de pago seleccionado por el
          titular: correo electrónico, referencia, respuesta de aprobación o
          rechazo del pago, folio de pago, autorización y confirmación bancaria,
          hora y fecha de autorización, tipo de tarjeta, institución bancaria
          que la emite, terminación de la tarjeta, monto pagado, vouchers de
          pago y mensajes de errores en caso de pagos rechazados.
          <br />
          <br />
          8. Cancelación de la Cuenta. Las cuentas que no reflejen prepagos
          durante un lapso de 4 meses a partir del último día de Servicio
          mencionado anteriormente podrán ser borradas junto con toda la
          información almacenada en los servidores de ACD NÓMINA, sin
          responsabilidad para esta última. Cada usuario y/o titular podrá
          cancelar su cuenta en el momento en que no realice el pago y/o
          mediante aviso por escrito a “contacto@acdnomina.com”, en cuyo caso no
          existirán bajo ninguna circunstancia devoluciones. En dicho caso, ACD
          NÓMINA interpretará que el usuario y/o titular ya no requiere más el
          servicio y por lo tanto otorga su autorización tácita a ACD NÓMINA
          para borrar sus datos y cualquier archivo generado por el titular, el
          usuario principal y/o sus usuarios secundarios y que se encuentre
          hospedado en nuestros servidores.
          <br />
          <br />
          9. Seguridad. ACD NÓMINA utiliza tecnología conforme a los más altos
          estándares del mercado para mantener respaldada y resguardada la
          información que alimentes al sistema y operaciones llevadas a cabo
          mediante el servicio. No obstante, actualmente no existe un método que
          impida al 100% la posibilidad de falla o de un acceso no autorizado a
          un sistema informático. En consecuencia, el usuario y/o titular acepta
          el riesgo inherente a la utilización del servicio y exime de toda
          responsabilidad a ACD NÓMINA, sus empleados, directivos, socios,
          empresas filiales, subsidiarias, socios comerciales, clientes y
          terceros perjudicados de cualquier daño que se pudiera ocasionar con
          la pérdida o mal uso de tu información como consecuencia de un acceso
          no autorizado. No está permitida ninguna acción o uso de dispositivo,
          software, u otro medio tendiente a interferir tanto en las actividades
          y operación de ACD NÓMINA, así como tampoco en las cuentas y bases de
          datos de ACD NÓMINA. Cualquier intromisión, tentativa o actividad
          violatoria o contraria a las leyes sobre derecho de propiedad
          intelectual y/o a las prohibiciones estipuladas en este contrato harán
          pasible a su responsable de las acciones legales pertinentes, y a las
          sanciones previstas por este acuerdo, así como lo hará responsable de
          indemnizar los daños ocasionados.
          <br />
          <br />
          10. Propiedad Intelectual. La titularidad de los derechos de propiedad
          intelectual sobre el software, programas, bases de datos, redes,
          archivos y demás funcionalidades y servicios corresponde a ACD NÓMINA.
          De la misma forma, la titularidad de los derechos sobre las marcas,
          avisos comerciales, nombres de dominio, signos distintivos y demás
          derechos de propiedad industrial que se encuentran en el sitio de ACD
          NÓMINA o forman parte de los servicios, corresponden a ACD
          CONSULTORES, S.A. DE C.V. El uso del servicio no concede al usuario
          y/o titular ningún tipo de propiedad, licencia, posesión, titularidad
          de dominio o autorización a titulares y usuarios de cuentas ACD NÓMINA
          para el uso o explotación de los derechos de propiedad intelectual
          correspondiente a ACD CONSULTORES, S.A. DE C.V. o los correspondientes
          a sus socios comerciales y empresas filiales o subsidiarias.
          <br />
          <br />
          11. Aviso de Privacidad. La información proporcionada por cada usuario
          y/o titular estará sujeta al Aviso de Privacidad de ACD NÓMINA.
          <br />
          <br />
          12. Indemnización. El usuario y/o titular indemnizará y mantendrá en
          paz y a salvo a ACD NÓMINA, sus filiales y subsidiarias, directivos,
          administradores, representantes y empleados, por cualquier reclamo o
          demanda de otros usuarios y/o titulares o de terceros, por sus
          actividades en el sitio o por el incumplimiento de los presentes
          TÉRMINOS Y CONDICIONES y demás políticas incorporadas al presente y/o
          que tengan que ver con la prestación del servicio que brinda ACD
          NÓMINA, así como por la violación de cualesquiera leyes o derechos de
          terceros.
          <br />
          <br />
          13. Modificaciones del Servicio. ACD NÓMINA se reserva el derecho de
          modificar o descontinuar temporal o permanentemente, de manera parcial
          o total, el servicio mediante notificación previa a los usuarios y/o
          titulares. Dicha notificación puede realizarse mediante el Portal
          Comercial, correo electrónico o por el mismo sistema de ACD NÓMINA.
          <br />
          <br />
          14. Modificaciones del Acuerdo. ACD NÓMINA podrá modificar los
          presentes TÉRMINOS Y CONDICIONES en cualquier momento haciendo
          públicos en el sitio los términos modificados. Todos los términos
          modificados entrarán en vigor a los 10 (diez) días de su publicación.
          Dentro de los 5 (cinco) días siguientes a la publicación de las
          modificaciones introducidas, el usuario y/o titular deberá comunicar
          por correo electrónico a: contacto@acdnomina.com si no acepta las
          mismas, en cuyo caso quedará disuelto el vínculo contractual y será
          cancelada su cuenta siempre y cuando no se tengan deudas pendientes.
          Vencido este plazo, se interpretará que el usuario y/o titular acepta
          los nuevos TÉRMINOS Y CONDICIONES y el contrato continuará vinculando
          a ambas partes.
          <br />
          <br />
          15. Jurisdicción. El presente acuerdo estará regido en todos sus
          puntos por las leyes vigentes en los Estados Unidos Mexicanos, en
          particular, respecto de mensajes de datos, contratación electrónica y
          comercio electrónico se regirá por lo dispuesto por la legislación
          federal respectiva. Cualquier controversia derivada del presente
          acuerdo, su existencia, validez, interpretación, alcance,
          cumplimiento, será sometida a las leyes aplicables y a los tribunales
          competentes. Para la interpretación, cumplimiento y ejecución del
          presente contrato, las partes expresamente se someten a la
          jurisdicción de los tribunales competentes de la ciudad de Chetumal,
          Quintana Roo <br />, renunciando en consecuencia a cualquier fuero que
          en razón de su domicilio presente o futuro pudiera corresponderles.
          <br />
          <br />
          16. Domicilio. Se establece como domicilio de ACD CONSULTORES, S.A. DE
          C.V., el ubicado en avenida Francisco I. Madero 241 L-11 M-21 “locales
          comerciales”, interior “C”, col. Venustiano Carranza, C.P. 77012.
          <br />
          <br />
          Para la aclaración de alguna duda sobre los presentes TÉRMINOS Y
          CONDICIONES o demás políticas y principios que rigen a ACD NÓMINA
          consulte nuestra página: “www.acdnomina.com”.
        </div>
        <br />
        <div style="padding-left:120px;">
          <a
            class=" btn btn-xl"
            style="background-color: #323387; color:white;"
            href="/"
            >Regresar</a
          >
        </div>
        <br />
        <br />
        <br />
      </section>
    </default-layout>
  </div>
</template>
<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
export default {
  name: "TerminosPage",
  components: {
    DefaultLayout
  }
};
</script>
